import { useEffect } from "react";
import { withRouter } from "react-router";
import debounce from "lodash.debounce";

function TealiumViewListener(props) {
    useEffect(
        () => {
            throttledRecordTealiumView(props?.history?.location?.pathname);
            let unlisten = props.history.listen((location) => {
                throttledRecordTealiumView(location?.pathname);
            });

            return () => unlisten();
        },
        // eslint-disable-next-line
        [],
    );

    const recordTealiumView = (pathname = "/") => {
        const fullname = props?.user?.fullName ?? null;
        const utag = window.utag;

        let page_category = pathname.replace("/", "");
        let page_subcategory = null;
        
        if (page_category === "") {
            page_category = fullname === null ? "login" : "articles";
        } else {
            const categories = page_category.split(/\/(.+)/);
            page_category = categories[0];
            page_subcategory = categories[1] ?? null;
        }

        const tealium_view_dict = {
            page_name: "Grow Smart Live",
            page_category,
            page_type: "react",
            page_language: "en",
            page_country: "usa",
            page_friendly_url: pathname,
            link_text: '',
            event_type: '',
            event_name: '',
            event_value: '',
            view_name: page_category
        };

        if (page_subcategory !== null) {
            tealium_view_dict.page_subcategory = page_subcategory;
        } else {
            tealium_view_dict.page_subcategory = '';
        }

        if (utag) {
            utag.view(tealium_view_dict);
        }
    };

    const throttledRecordTealiumView = debounce(recordTealiumView, 500);

    return null;
}

export default withRouter(TealiumViewListener);
