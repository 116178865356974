import "react-app-polyfill/ie11";
import React, { useEffect } from "react";
import jQuery from "jquery";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { withInnercastCore } from "innercast-core-js/dist/module/index";
import { WebCore } from "@linxsystems/web-core";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store";
import { AuthGroup, HOST, PERFECT_TENSE_KEY, PUSHER_KEY, RECAPTCHA_SITE_KEY, TEALIUM_ENV } from "./Constants";

import { useScript } from "./lib/hooks";
import ErrorScreen from "containers/ErrorScreen";

import bugsnagClient from "lib/bugsnag";
import ResponsiveManager from "ResponsiveManager";

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin("react");

if (TEALIUM_ENV !== "dev") {
    console.log = () => {};
}

ReactDOM.render(
    withInnercastCore(
        <WebCore
            basename="/BASF"
            validateEndpoint="/API/v1/validate"
            host={HOST}
            authGroup={AuthGroup}
            theme="GrowSmartLive"
            appName="Grow Smart™ Live"
            debug={false}>
            <ErrorBoundary FallbackComponent={<ErrorScreen />}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <App basename={"/BASF"} />
                        {!window.location.pathname.includes("mobile") && !window.location.pathname.includes("videos") && (
                            <TealiumAnalytics env={TEALIUM_ENV} />
                        )}
                        <ResponsiveManager />
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </WebCore>,
        {
            basename: "/BASF",
            validateEndpoint: "/API/v1/validate",
            HOST: HOST,
            AuthGroup: AuthGroup,
            Theme: "GrowSmartLive",
            PUSHER_KEY: PUSHER_KEY,
            PERFECT_TENSE_KEY: PERFECT_TENSE_KEY,
            AppName: "Grow Smart™ Live",
            RECAPTCHA_SITE_KEY: RECAPTCHA_SITE_KEY,
        },
    ),
    document.getElementById("root"),
);
registerServiceWorker();

if (window.Cypress) {
    window.store = store;
}

function TealiumAnalytics({ env = "dev" }) {

    useEffect(() => {
        window.$ = window.jQuery = jQuery;
    }, []);

    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;

    useScript(`https://tags.tiqcdn.com/utag/basf/us-crop/${env}/utag.js`);

    return null;
}
